import Link from "next/link";
import Image from "next/image";
import classNames from "classnames";
import parse from "html-react-parser";
import s from "./Custom404Module.module.scss";
import Container from "react-bootstrap/Container";
import shape from "../../../public/images/custom404/Shape.png";
import shape2 from "../../../public/images/custom404/Shape2.png";

export default function ConfirmationModule({fields}) {
  return (
    <section className={classNames(s.hero_section)}>
      <div className={classNames(s.main_div)}>
        <Container>
          <div className={classNames(s.text_div)}>
            {fields.headline && (
              <h1 className={classNames(s.headline)}>
                {parse(fields.headline)}
              </h1>
            )}

            {fields.subheadline && (
              <h1 className={classNames(s.subheadline)}>
                {parse(fields.subheadline)}
              </h1>
            )}

            {fields.cta_button_text && (
              <Link href={`/`} legacyBehavior={true}>
                <a
                  className={classNames(
                    "btn",
                    "btn-primary",
                    "error-button",
                    s.button
                  )}
                >
                  {fields.cta_button_text}
                </a>
              </Link>
            )}
          </div>
        </Container>

        <div className={classNames(s.vector)}>
          <Image
            src={shape}
            alt="Vector Shape"
            width={420}
            height={210}
            className={s.vector_image}
          />
        </div>

        <div className={classNames(s.vector2)}>
          <Image
            src={shape2}
            alt="Vector Shape"
            width={420}
            height={420}
            className={s.vector2_image}
          />
        </div>
      </div>
    </section>
  );
}
